.wallet-connect{
    margin-bottom: 16px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #c5c4c4 ;
    padding : 4px;
    cursor: pointer;
}
.wallet-connect:hover{
    border: 1px solid #a8b7fa ;
}