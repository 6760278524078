.market-screen{
  width: 100vw;
  min-height:100vh;
}
.down-border{
  padding-bottom: 8px;
  border-bottom: 1px solid white;
}


.page-title{
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.sort-item{
  width:200px;
  display: flex;
  flex-wrap: nowrap;
  float: right;

}
.sort-item-label{

  color:white;
  font-size:18px;
  margin: 0px 8px;
}
.sort-select{
  flex-grow: 1;
  display: inline-block;
}