.intro-back{
    background-color: black;
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 16px;
    border-bottom: 1px solid gray;
    margin-top: 100px;
}
.intro-img{
    width: 16%;
    border-radius: 20px;
    display: inline-block;
    margin: 64px 16px;

}
@media screen and (max-width: 1240px) {
    .intro-img{
        width: 70%;
    }
}
