.NFT-view{
    width: 100vw;
    min-height: 100vh;
    color: white;
    font-size: 16px;
    z-index: 10;
    top: 0px;
    left: 0px;
    background-color: black;
    align-items: center;
    justify-content: center;
}
.NFT-modal{
    background: white;
    color: black;
    padding: 16px;
    border-radius: 16px;
}
.title-green{
    color: #1a6c09;
}
.title-24{
    font-size: 24px;
    font-weight: bold;
}
.round{
    border-radius: 16px;
    border: 1px solid black;
    padding: 0px 8px;
    margin-right:8px;
    display: inline-block;
    margin-top: 4px;
}
.NFT-image{
    width:100%;
    border-radius:12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}