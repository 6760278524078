.modal-back{
    width:100vw;
    height:100vh;
    position:fixed;
    background-color: rgba(0, 0, 0, 0.7);

    z-index: 10;
    top: 0px;
    left: 0px;
}
.modal-title{
    font-size: 24px;
    color:black;
    font-weight:bolder;
    text-align: center;
}
.modal{
    background-color: white;
    padding: 32px;
    border-radius: 32px;
}