.NFT-card{
    text-align: left;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    cursor: pointer;
    padding: 8px;
    border: 2px solid #52c41a;

}
.NFT-card:hover{
    // box-shadow: 0 4px 8px 0 rgb(2, 252, 239), 0 6px 20px 0 rgba(2, 252, 239, 0.19);
    border: 2px solid rgb(0, 255, 255) ;
    outline: 2px solid rgb(0, 255, 255) ;

}

.NFT-id{
    font-size: 24px;
    color: black;
}
@media screen and (max-width: 768px) {
    .NFT-id{
        font-size: 14px;
        color: black;
    }
}
.NFT-img{
    width: 150px;
    height: 150px;
    background-color: red;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius:12px 12px 12px 12px;
}
.NFT-rank{
    font-size: 16px;
    color: #153a02;
}