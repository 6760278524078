.mint-back{
    background-color: rgba(0,0,0,0.5);
    color: white;
    font-size: 20px;
    text-align: center;
    padding:16px;
    border-radius: 32px;
}

.mint-button{
    margin-top: 16px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;

    /* button/primary/default */
    background: linear-gradient(180deg, #fd8f8f 0%, #ff6262 100%);
    border-radius: 8px;
    height: 58px;

    /* caps button text */
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 1px;
    text-transform: uppercase;

    /* text/default */
    color: #fff;
}
.loading-button{
    margin-top: 16px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;

    /* button/primary/default */
    border-radius: 8px;
    height: 58px;

    /* caps button text */
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 1px;
    text-transform: uppercase;

    /* text/default */
    color: #fff;
}
.total-price{
    display: inline;
}
@media screen and (max-width: 768px) {
    .total-price{
        display: block;
    }
}