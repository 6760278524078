.footer{
    color: white;
}
.footer-item{
    margin-right:10px;
    margin-left:10px;
    display: inline-block;
}
.footer-item:hover{
    color:#00e1ff;
}
.div-right{
    flex-grow: 1;
    width: 50%;
}
.text-right{
    text-align: right;
}
.div-flex{
    text-align: left;
}
.foot-button{
    color: white;
}
.foot-button:hover{
    color:turquoise;
}
.text-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
    .div-flex{
        text-align: center;
    }
    .text-right{
        text-align: center;
    }
}
