@import '../../styles/color.less';

.search-back{
    background-color: transparent;
    color: white;
    outline: white solid 1px;
    font-size: 16px;
    border-radius: 3px;
    border: none;
    padding:2px 8px;
    width: 100%;
    transition: outline 0.5s;
}
.search-back:focus{
    outline: @link-color solid 1px;
}